import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import {
	process,
	checkImgExists
} from '../common/api/process.js'
import {
	byteToString,
	stringToHex
} from "../common/api/bytetostring.js"
import media from "../assets/img/job.png"
export default new Vuex.Store({
	state: {
		dic: '',
		key: "",
		key2: "",
		clientObj:{},
		mechanismname: '',
		mechanismaccount: '',
		mechanismpassword: '',	
		xinoptions: [{
			value: '0',
			label: '0-5000'
		}, {
			value: '1',
			label: '5000-8000'
		}, {
			value: '2',
			label: '8000-11000'
		}, {
			value: '3',
			label: '11000-15000'
		}, {
			value: '4',
			label: '15000-20000'
		}, {
			value: '5',
			label: '20000-30000'
		}, {
			value: '6',
			label: '30000-50000'
		}, 
		{
			value: '7',
			label: '50000以上'
		}]
	},
	mutations: {
		getdid() {
			let str = "23014502zx8cvbn7masdfg5hjklq6w9ertyu0iop4167893";
			let res = '';
			for (var i = 0; i < 16; i++) {
				res += str[Math.floor(Math.random() * str.length)];
			}
			let did=res
			localStorage.setItem("did", did);
		},
	getKey(state) {
		let did = localStorage.getItem("did")
		let dic = state.dic;
		let len = 16;
		if (!did || len <= 0) {
			return "";
		}
		try {
			let seed = stringToHex(did);
			let seedGroupSize = parseInt(seed.length / len);
			let dicGroupSize = parseInt(dic.length / len);
			let key = "";
			for (let l = 0; l < len; l++) {
				let sdg = seed.substring(l * seedGroupSize, (l + 1) * seedGroupSize);
				let index = parseInt(sdg, 16) % dicGroupSize;
				let start = dicGroupSize * l + index;
				key += dic.charAt(start);
			}
			let getKey = key
			state.key = getKey
			return key
		} catch (e) {
			// console.log(e);
		}
		return "";
	},
	getKeys(state) {
		let did = localStorage.getItem("did")
		let dic = state.dic;
		let len = 8;
		if (!did || len <= 0) {
			return "";
		}
		try {
			let seed = stringToHex(did);
			let seedGroupSize = parseInt(seed.length / len);
			let dicGroupSize = parseInt(dic.length / len);
			let key = "";
			for (let l = 0; l < len; l++) {
				let sdg = seed.substring(l * seedGroupSize, (l + 1) * seedGroupSize);
				let index = parseInt(sdg, 16) % dicGroupSize;
				let start = dicGroupSize * l + index;
				key += dic.charAt(start);
			}
			let getKey = key
			state.key2 = getKey
			return key
		} catch (e) {
			// console.log(e);
		}
		return "";
	}
},
actions: {
	initDic({
		commit,
		state
	}) {
		return new Promise((resolve, reject) => {
			let xhr = new XMLHttpRequest();
			let url = media;
			checkImgExists(url).then(() => {
				xhr.open("GET", url, true);
				xhr.responseType = "arraybuffer";
				xhr.onload = function() {
					if (xhr.readyState === xhr.DONE) {
						if (xhr.status === 200) {
							var bytes = process(xhr.response);
							state.dic = byteToString(bytes);
							commit('getKey')
							commit('getKeys')
							resolve()
						}
					}
				};
				xhr.send();
			}).catch(() => {
				console.log('无效图片链接')
				reject()
			})
		})
	}
},
modules: {}
})
