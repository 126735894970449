import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
Vue.prototype.axios = axios
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/css/swiper.css'
import 'lib-flexible'
import 'vue-area-linkage/dist/index.css'; 
import VueAreaLinkage from 'vue-area-linkage';
import VueLazyload from 'vue-lazyload'
// 配置项
Vue.use(VueLazyload, {
 error: require('./assets/img/err.png'),
 loading: require('./assets/img/loading.gif'),
 attempt:6
})
Vue.use(VueAreaLinkage)
Vue.config.productionTip = false
Vue.use(ElementUI);
import './common/font.css'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
