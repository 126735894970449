import CryptoJS from 'crypto-js'
const iv = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f');
//加密	
function enc(data, keys) {
	var strWordArray = CryptoJS.enc.Utf8.parse(data);
	var strUint8Array = CryptoJS.enc.Uint8Array.stringify(strWordArray);
	var wordArray = CryptoJS.enc.Uint8Array.parse(strUint8Array);
	//

	let encrypted = CryptoJS.AES.encrypt(wordArray, CryptoJS.enc.Utf8.parse(keys), {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});

	var bv = CryptoJS.enc.Uint8Array.stringify(encrypted.ciphertext);

	return bv;
}
//解密
function dec(data, keys) {
	let wdata = unit8ArrayToWordsArray(data);
	let decrypt = CryptoJS.AES.decrypt({
		ciphertext: wdata
	}, CryptoJS.enc.Utf8.parse(keys), {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});

	let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
	return decryptedStr.toString();
}
function unit8ArrayToWordsArray(u8arr) {
	var len = u8arr.length;
	var words = [];
	for (var i = 0; i < len; i++) {
		words[i >>> 2] |= (u8arr[i] & 0xff) << (24 - (i % 4) * 8);

	}
	return CryptoJS.lib.WordArray.create(words, len);

}
//转换
CryptoJS.enc.Uint8Array = {
	/**	
	 * WordArray转Uint8Array
	 */

	stringify: function(wordArray) {
		var words = wordArray.words;

		var sigBytes = wordArray.sigBytes;

		var u8 = new Uint8Array(sigBytes);

		for (var i = 0; i < sigBytes; i++) {
			var byte = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;

			u8[i] = byte;

		}

		return u8;

	},

	/**	
	 * Uint8Array转WordArray
	 */

	parse: function(u8arr) {
		var len = u8arr.length;

		var words = [];

		for (var i = 0; i < len; i++) {
			words[i >>> 2] |= (u8arr[i] & 0xff) << (24 - (i % 4) * 8);

		}

		return CryptoJS.lib.WordArray.create(words, len);

	}

};


export {
	enc,
	dec,

}
