//byte数组转换string
function byteToString(arr) {
	if (typeof arr === 'string') {
		return arr;
	}
	var str = '',
		_arr = arr;
	for (var i = 0; i < _arr.length; i++) {
		var one = _arr[i].toString(2),
			v = one.match(/^1+?(?=0)/);
		if (v && one.length == 8) {
			var bytesLength = v[0].length;
			var store = _arr[i].toString(2).slice(7 - bytesLength);
			for (var st = 1; st < bytesLength; st++) {
				store += _arr[st + i].toString(2).slice(2);
			}
			str += String.fromCharCode(parseInt(store, 2));
			i += bytesLength - 1;
		} else {
			str += String.fromCharCode(_arr[i]);
		}
	}
	return str;
}
//转换
function stringToHex(str) {
	let val = "",
		i;
	for (i = 0; i < str.length; i++) {
		val += str.charCodeAt(i).toString(16);
	}
	return val;
}
//转换
function pars(param) {
	var keys = Object.keys(param).sort();
	var first = true;
	var content = "";
	for (var key in keys) {
		var k = keys[key];
		var val = param[k];
		if (first) {
			content += k + "=" + val;
			first = false;
		} else {
			content += "&" + k + "=" + val;
		}
	}
	return content;
}
//去空
function removeProperty(object){
  for(var k in object){
    if (object[k]==='') {
      delete object[k]
    }
  }
}
export {
	byteToString,
	stringToHex,
	pars,
	removeProperty
}
