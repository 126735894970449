//登录接口
const login = '/xunniu/login'
//行业分类信息
const industryInfo='/xunniu/post/industryInfo'
//首页职位列表
const homePosts='/xunniu/post/homePosts'
//职位详情页
const postDetail='/xunniu/post/postDetail'
//公司详细信息
const detail='/xunniu/company/detail'
//退出登录
const logout='/xunniu/logout'
//招聘信息页
const recruitmentInfo='/xunniu/post/recruitmentInfo' 
 //获取招聘名片信息
 const userProfile='/xunniu/mine/userProfile'
//编辑招聘名片信息
const editUserProfile='/xunniu/mine/editUserProfile'
//发布职位接口
const pubPost='/xunniu/mine/pubPost'
//个人注册
const registerUser='/xunniu/registerUser'
//机构用户注册
const registerCompanyUser='/xunniu/registerCompanyUser'
//获取oss配置
const upConf='/app/upConf'
//获取公司福利待遇
const benefits='/xunniu/company/benefits'
//编辑公司福利待遇
const editBenefits='/xunniu/company/editBenefits'
//编辑招聘职位接口
const editPubPost='/xunniu/mine/editPubPost'
//已发布招聘职位列表
const pubPostList='/xunniu/mine/pubPostList'
//删除已发布的招聘岗位
const delPubPost='/xunniu/mine/delPubPost'
//获取已发布职位接口
const getPubPost='/xunniu/mine/getPubPost'
//发布期望职位
const pubExpectPost='/xunniu/mine/pubExpectPost'
//获取发布期望职位
const expectPost='/xunniu/mine/expectPostList'
//获取期望职位内容
const getExpectPost='/xunniu/mine/getExpectPost'
//删除一个期望职位
const delExpectPost='/xunniu/mine/delExpectPost'
//职位轮播列表
const carouselPosts='/xunniu/post/carouselPosts'
//关于我们
const webInfo='/xunniu/webInfo'
//首页职业
const randomPosts='/xunniu/post/randomPosts'
export {
	login,industryInfo,homePosts,postDetail,logout,detail,recruitmentInfo,editUserProfile,userProfile,pubPost,registerUser,registerCompanyUser,
	upConf,benefits,editBenefits,editPubPost,pubPostList,delPubPost,getPubPost,pubExpectPost,expectPost,getExpectPost,delExpectPost,carouselPosts,
	webInfo,randomPosts
	}