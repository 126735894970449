//提取字典
function process(buffer) {
	let byteArray = new Uint8Array(buffer);
	let len = 0,
		bit = 0,
		bitVal, i;
	for (i = 54; i < 86; i++) {
		bitVal = byteArray[i];
		len += (bitVal & 0x01) << (bit++);
	}
	bit = 0;
	let ch = 0,
		bytes = [];
	for (i = 86; i < byteArray.length;) {
		if (bit < 8) {
			let byte = byteArray[i++];
			bitVal = (byte & 0x01) << bit;
			if ((bitVal >> 7) === 1 && bit === 7) {
				ch -= bitVal;
			} else {
				ch += bitVal;
			}
			bit++;
			continue;
		}
		bytes.push(ch);
		ch = 0;
		bit = 0;
		if (bytes.length >= len) {
			break;
		}
	}
	return bytes;
}
//判断图片是否有效
function checkImgExists(imgurl) {
	return new Promise(function(resolve, reject) {
		var ImgObj = new Image();
		ImgObj.src = imgurl;
		ImgObj.onload = function(res) {
			resolve(res);
		}
		ImgObj.onerror = function(err) {
			reject(err)
		}
	})
}
export {
	process,
	checkImgExists
}
