<template>
	<div class="bottom">
		<!-- <div>Copyright️ © {{about.copyright}}</div> -->
		<!-- <div>Copyright©2019 168zqb.com 四川谷丰旺成网络科技有限公司</div> -->
		<!-- <div class="bottom-main"><a href="https://beian.miit.gov.cn/">版权所有 ICP证：蜀ICP备2022002573号</a></div> -->
		<div>Copyright© 2019-2024 xuniuwang.com 成都迅牛网络科技有限公司</div>
		<div class="bottom-main"><a href="https://beian.miit.gov.cn/" target="_blank">版权所有 ICP证：蜀ICP备19016799号-1</a></div>
	</div>
</template>

<script>
	import $http from '../../common/api/axios.js'
	import {
		webInfo
	} from '../../common/api/api.js'
	export default {
		data() {
			return{
				about:{}
			}
		},
		created() {
			let that = this
			this.$store.dispatch('initDic').then(() => {
				$http.request({
					url:webInfo,
				}).then((res) => {
					// console.log(res)
					that.about=res.data
				}).catch(() => {
				
				});
				
			})
		}
	}
</script>

<style scoped>
	.bottom {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 19.2rem;
		height: 2.03rem;
		background:#3C3C3C ;
	}
	
	.bottom div {
		font-size: .18rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: .24rem;
	}
	.bottom-main>a{
		color: #9092ff;
	}
</style>
